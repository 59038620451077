import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { getEvents } from "../../actions/eventsActions";
import { getVideos } from "../../actions/videosActions";
import { getSlides } from "../../actions/slidesActions";
import { getConfig } from "../../actions/appConfigActions";
import { getGiftSettings } from "../../actions/giftsActions";
import Wrapper from "../wrappers/Wrapper";
import FutureEvents from "./events/FutureEvents";
import AvailableVideos from "./videos/AvailableVideos";
import BirthdayChangeInform from "./BirthdayChangeInform";
import SlickSlider from "./slider/SlickSlider";
import { Skeleton } from "antd";
import {
  reverseVodEventsOrder,
  vodText,
  repertoireText,
  vodTextEn,
  repertoireTextEn,
  giftText,
  giftTextEn,
} from "../../theme-variables";
import AcceptTos from "./AcceptTos";
import { withTranslation } from "react-i18next";
import FestivalEvents from "./festival/FestivalEvents";
import AddressChangeInform from "./AddressChangeInform";
import GiftBlock from "./gifts/GiftBlock";

class Dashboard extends React.Component {
  state = { currentLanguage: "pl" };
  componentDidMount() {
    this.props.getEvents();
    this.props.getVideos();
    this.props.getSlides();
    this.props.getConfig(false);
    this.props.getGiftSettings();
  }
  renderVod(videos, vodFilters, show) {
    if (show)
      return (
        <>
          <h2>
            {this.props.i18n.language === "pl"
              ? vodText || this.props.t("dashboard.vod_title")
              : vodTextEn || this.props.t("dashboard.vod_title")}
          </h2>
          <AvailableVideos allowFilters={vodFilters} videos={videos} />
          <br />
        </>
      );
  }
  renderRepertoire(events, show) {
    if (show)
      return (
        <>
          <h2>
            {this.props.i18n.language === "pl"
              ? repertoireText || this.props.t("dashboard.repertoire_title")
              : repertoireTextEn || this.props.t("dashboard.repertoire_title")}
          </h2>
          <FutureEvents events={events} />
          <br />
        </>
      );
  }
  renderFestival(events, show) {
    const {
      festival_title,
      festival_title_en,
      festival_short_dates,
      festival_show_finished,
      festival_reverse_finished,
    } = this.props.config;
    if (show)
      return (
        <>
          <h1>
            {this.props.i18n.language === "pl" ? (
              festival_title ? (
                festival_title
              ) : (
                <span>Festiwal</span>
              )
            ) : festival_title_en ? (
              festival_title_en
            ) : (
              <span>Festival</span>
            )}
          </h1>
          <FestivalEvents
            shortDates={
              festival_short_dates === 1 || festival_short_dates === true
            }
            showFinished={
              festival_show_finished === 1 || festival_show_finished === true
            }
            reverseFinished={
              festival_reverse_finished === 1 ||
              festival_reverse_finished === true
            }
            events={events}
          />
          <br />
        </>
      );
  }
  renderGiftBlock() {
    const { any_gift_available, gift_expiration } = this.props.giftConfig;
    if (!any_gift_available || moment(gift_expiration).isBefore(moment()))
      return null;

    return (
      <div id="prezent">
        <h2>
          {this.props.i18n.language === "pl"
            ? giftText || this.props.t("dashboard.gift_title")
            : giftTextEn || this.props.t("dashboard.gift_title")}
        </h2>
        <GiftBlock
          config={this.props.giftConfig}
          language={this.props.i18n.language}
        />
      </div>
    );
  }
  render() {
    const { events } = this.props.events;
    const { videos } = this.props.videos;
    const repertoireEvents = events.filter(
      (event) => event.is_festival === false || event.is_festival === 0
    );
    const festivalEvents = events.filter(
      (event) => event.is_festival === true || event.is_festival === 1
    );
    const {
      view_slider,
      view_eventslist,
      view_vod,
      view_festival,
      vod_filters,
    } = this.props.config;
    if (!this.props.loadingConfig)
      return (
        <Wrapper fullWidth={true}>
          {view_slider ? <SlickSlider slides={this.props.slides} /> : null}
          {/* <BirthdayChangeInform /> */}
          {/* <AddressChangeInform /> */}
          <AcceptTos />
          <div className="content-wrapper">
            <div className="content">
              {this.renderFestival(festivalEvents, view_festival)}
              {reverseVodEventsOrder ? (
                <>
                  {this.renderVod(videos, vod_filters, view_vod)}
                  {this.renderRepertoire(repertoireEvents, view_eventslist)}
                </>
              ) : (
                <>
                  {this.renderRepertoire(repertoireEvents, view_eventslist)}
                  {this.renderVod(videos, vod_filters, view_vod)}
                </>
              )}
              {this.renderGiftBlock()}
            </div>
          </div>
        </Wrapper>
      );
    else
      return (
        <Wrapper>
          <Skeleton />
        </Wrapper>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    events: state.events,
    videos: state.videos,
    token: state.auth.token,
    slides: state.slides,
    config: state.appConfig.config,
    loadingConfig: state.appConfig.loading,
    birthday: state.auth.user ? state.auth.user.birthday : null,
    giftConfig: state.gifts.config,
  };
};

export default connect(mapStateToProps, {
  getEvents,
  getVideos,
  getSlides,
  getConfig,
  getGiftSettings,
})(withTranslation()(Dashboard));
